import React, { Component } from 'react';

const encode = (data) => {
	return Object.keys(data)
		.map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
		.join('&');
};

class ContactOne extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			email: '',
			subject: '',
			message: '',
		};
	}

	handleSubmit = (e) => {
		fetch('/', {
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: encode({ 'form-name': 'contact', ...this.state }),
		})
			.then(() => alert('Success!'))
			.catch((error) => alert(error));

		e.preventDefault();
	};

	handleChange = (e) => this.setState({ [e.target.name]: e.target.value });

	render() {
		return (
			<div className='contact-form--1'>
				<div className='container'>
					<div className='row row--35 align-items-start'>
						<div className='col-lg-6 order-2 order-lg-1'>
							<div className='section-title text-left mb--50'>
								<h2 className='title'>Contact Me.</h2>
								<p className='description'>
									I am available for work or for questions! Connect with me via
									phone: <a href='tel:+12246278147'>224-627-8147</a> or email me at:
									<a href='mailto:me@johnnycoding.com'> me@johnnycoding.com</a>{' '}
								</p>
							</div>
							<div className='form-wrapper'>
								<form onSubmit={this.handleSubmit} name="contact" data-netlify="true">
									<input type='hidden' value='contact' name='form-name' />
									<label htmlFor='item01'>
										<input
											type='text'
											name='name'
											id='item01'
											value={this.state.name}
											onChange={this.handleChange}
											placeholder='Your Name *'
										/>
									</label>

									<label htmlFor='item02'>
										<input
											type='text'
											name='email'
											id='item02'
											value={this.state.email}
											onChange={this.handleChange}
											placeholder='Your email *'
										/>
									</label>

									<label htmlFor='item03'>
										<input
											type='text'
											name='subject'
											id='item03'
											value={this.state.subject}
											onChange={this.handleChange}
											placeholder='Write a Subject'
										/>
									</label>
									<label htmlFor='item04'>
										<textarea
											type='text'
											id='item04'
											name='message'
											value={this.state.message}
											onChange={this.handleChange}
											placeholder='Your Message'
										/>
									</label>
									<button
										className='rn-button-style--2 btn-solid'
										type='submit'
										value='submit'
										name='submit'
										id='mc-embedded-subscribe'
									>
										Submit
									</button>
								</form>
							</div>
						</div>
						<div className='col-lg-6 order-1 order-lg-2'>
							<div className='thumbnail mb_md--30 mb_sm--30'>
								<img
									src='https://ik.imagekit.io/sdlmfkhptke/maingdl_NdQCzxVBDDn.jpg?updatedAt=1628016118888'
									alt='Me in Guadalajara, Jalisco, Mexico'
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default ContactOne;
