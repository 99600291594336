import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';


import HeaderThree from '../component/header/HeaderThree';
import FooterTwo from '../component/footer/FooterTwo';

import TabTwo from '../elements/tab/TabTwo';

import ContactOne from '../elements/contact/ContactOne';

import PortfolioList from '../elements/portfolio/PortfolioList';

import ServiceList from '../elements/service/ServiceList';

import BlogContent from '../elements/blog/BlogContent';

import Helmet from '../component/common/Helmet';

import TextLoop from 'react-text-loop';

const SlideList = [
	{
		textPosition: 'text-left',
		category: 'Software Engineer',
		title: 'Hello, I’m <span>Johnny</span> <br/>Welcome to my World.',
		description: '',
		buttonText: '',
		buttonLink: '',
	},
];
const PortfolioLanding = () => {
	let title = 'About Me',
		description =
			'Hi, I’m Johnny! For the past few years, I’ve been at Harvard University studying one of my biggest passions, mathematics, as well as helping others get into their dream school.Throughout those years, I discovered my biggest passion which is problem-solving. But problem-solving for the greater good of humanity, which is why I am pursuing a career in Software Engineering. Writing and creating software gives me the possibility (ability)to turn people’s problems into solutions with software. Not only will I be able to impact more people’s lives, but the possibilities with writing software are also endless. Just like in math, there is always a way to solve a problem, we just need to figure out a solution. Eventually, I want to have the possibility to build AI that is so good that it can be used as a reliable psychologist.';
	const PostList = BlogContent.slice(0, 3);
	return (
		<div>
			<Helmet pageTitle='Johnny Coding' />
			<HeaderThree homeLink='/' logo='symbol-dark' color='color-black' />
			{/* Start Slider Area   */}
			<div id='home' className='fix'>
				<div className='slider-wrapper'>
					{/* Start Single Slide */}
					{SlideList.map((value, index) => (
						<div
							className='slide designer-portfolio slider-style-3  d-flex align-items-center justify-content-center bg_color--5 rn-slider-height'
							key={index}
						>
							<div className='container'>
								<div className='row align-items-center'>
									<div className='col-lg-5'>
										<div className='designer-thumbnail'>
											<img
												src='https://ik.imagekit.io/sdlmfkhptke/bw_E7QRG1GgY.jpg?updatedAt=1628016118449'
												alt='Slider Images'
											/>
										</div>
									</div>
									<div className='col-lg-7 mt_md--40 mt_sm--40'>
										<div className={`inner ${value.textPosition}`}>
											{value.category ? <span>{value.category}</span> : ''}
											<h1 className='title'>
												Hi, I’m Johnny <br />
												<TextLoop>
													<span> Front-End.</span>
													<span> Back-End.</span>
													<span> Fullstack.</span>
												</TextLoop>{' '}
											</h1>
											<h2>based in Chicago, Illinois.</h2>
											{value.description ? (
												<p className='description'>{value.description}</p>
											) : (
												''
											)}
											{value.buttonText ? (
												<div className='slide-btn'>
													<a
														className='rn-button-style--2 btn-primary-color'
														href={`${value.buttonLink}`}
													>
														{value.buttonText}
													</a>
												</div>
											) : (
												''
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					))}
					{/* End Single Slide */}
				</div>
			</div>
			{/* End Slider Area   */}

			{/* Start About Area */}
			<div id='about' className='fix'>
				<div className='about-area ptb--120  bg_color--1'>
					<div className='about-wrapper'>
						<div className='container'>
							<div className='row row--35 align-items-center'>
								<div className='col-lg-5'>
									<div className='thumbnail'>
										<img
											className='w-100'
											src='/assets/images/about/tequila.jpg'
											alt='About Images'
										/>
									</div>
								</div>
								<div className='col-lg-7'>
									<div className='about-inner inner'>
										<div className='section-title'>
											<h2 className='title'>{title}</h2>
											<p className='description'>{description}</p>
										</div>
										<div className='row mt--30'>
											<TabTwo tabStyle='tab-style--1' />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* End About Area */}

			{/* Start Service Area  */}
			<div id='service' className='fix'>
				<div className='service-area creative-service-wrapper ptb--120 bg_color--5'>
					<div className='container'>
						<div className='row'>
							<div className='col-lg-12'>
								<div className='section-title text-center service-style--3 mb--30 mb_sm--0'>
									<h2 className='title'>Skills</h2>
									<p>
										"The future belongs to those who learn more skills and
										combine them in creative ways"
									</p>
								</div>
							</div>
						</div>
						<div className='row creative-service'>
							<div className='col-lg-12'>
								<ServiceList
									item='6'
									column='col-lg-4 col-md-6 col-sm-6 col-12 text-left'
								/>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col-lg-12'>
							<div className='view-more-btn mt--60 mt_sm--30 text-center'>
								<a
									className='rn-button-style--2 btn-solid'
									href='https://drive.google.com/file/d/123HHeoFNDnz2xs4J-YTEc06QmmZ-huR6/view?usp=sharing'
									target='_blank'
								>
									<span>View My Resume</span>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Service Area  */}

			{/* Start Portfolio Area */}
			<div id='portfolio' className='fix'>
				<div className='portfolio-area ptb--120 bg_color--1'>
					<div className='portfolio-sacousel-inner'>
						<div className='container'>
							<div className='row'>
								<div className='col-lg-12'>
									<div className='section-title text-center service-style--3 mb--30 mb_sm--0'>
										<h2 className='title'>My Latest Projects</h2>
										<p>
											Projects are my way of mastering and learning new
											concepts. My goal everytime is to build something that I
											can learn from whether as a person or engineer.
										</p>
									</div>
								</div>
							</div>
							<div className='row'>
								<PortfolioList
									styevariation='text-center mt--40'
									column='col-lg-4 col-md-6 col-sm-6 col-12'
									item='6'
								/>
							</div>
							<div className='row'>
								<div className='col-lg-12'>
									<div className='view-more-btn mt--60 mt_sm--30 text-center'>
										<a
											className='rn-button-style--2 btn-solid'
											href='http://github.com/johnnyvcoding'
											target='_blank'
										>
											<span>View More</span>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Portfolio Area */}

			{/* Start Blog Area */}
			{/* <div id='blog' className='fix'>
				<div className='rn-blog-area ptb--120 bg_color--5 mb-dec--30'>
					<div className='container'>
						<div className='row align-items-end'>
							<div className='col-lg-12 col-md-12 col-sm-12 col-12'>
								<div className='section-title text-center'>
									<h2>Latest News</h2>
									<p>
										There are many variations of passages of Lorem Ipsum
										available, but the majority have suffered alteration.
									</p>
								</div>
							</div>
						</div>
						<div className='row mt--60 mt_sm--40'>
							{PostList.map((value, i) => (
								<div className='col-lg-4 col-md-6 col-12' key={i}>
									<div className='blog blog-style--1'>
										<div className='thumbnail'>
											<a href='/blog-details'>
												<img
													className='w-100'
													src={`/assets/images/blog/blog-${value.images}.jpg`}
													alt='Blog Images'
												/>
											</a>
										</div>
										<div className='content'>
											<p className='blogtype'>{value.category}</p>
											<h4 className='title'>
												<a href='/blog-details'>{value.title}</a>
											</h4>
											<div className='blog-btn'>
												<a className='rn-btn text-white' href='/blog-details'>
													Read More
												</a>
											</div>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div> */}
			{/* End Blog Area */}

			{/* Start COntact Area */}
			<div id='contact' className='fix'>
				<div className='rn-contact-area ptb--120 bg_color--1'>
					<ContactOne />
				</div>
			</div>
			{/* End COntact Area */}

			<FooterTwo />
			{/* Start Back To Top */}
			<div className='backto-top'>
				<ScrollToTop showUnder={160}>
					<FiChevronUp />
				</ScrollToTop>
			</div>
			{/* End Back To Top */}
		</div>
	);
};

export default PortfolioLanding;
